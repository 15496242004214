@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lato&display=swap');

$clr-line: grey;


body,
html {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: rgb(247, 246, 244);
}
/*
  Make the scroll bar nicer, otherwise
  it detracks from the beauty of the tabs ;-)
*/
::-webkit-scrollbar
{
  width: 7px;  /* for vertical scrollbars */
  height: 7px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track
{
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb
{
  background: $clr-line;
}
body {
    scrollbar-face-color: $clr-line;
    scrollbar-shadow-color: $clr-line;
    scrollbar-highlight-color: #f1f1f1;
    scrollbar-3dlight-color: #f1f1f1;
    scrollbar-darkshadow-color: $clr-line;
    scrollbar-track-color: #f1f1f1;
    scrollbar-arrow-color: $clr-line;
}

.rct-tree-root {
  font-family: 'Inter', sans-serif !important;
}

a[id="#"] {       
  text-decoration: none;
  color:black;
  cursor: auto;
}

a#Begrippen {
  color: #fff !important;
  background-color: #22A8E1;
  text-decoration: none;
  pointer-events: none;
}

a#Locaties {
  color: #fff;
  background-color: #e60094;
  text-decoration: none;
  pointer-events: none;
}

a#Normen {
  color: #fff;  
  background-color: #F15A24;
  text-decoration: none;
  pointer-events: none;
}

a#Waarde {
  color: #fff;
  background-color: #F15A24;
  text-decoration: none;
  pointer-events: none;
}

.panelSplitVertical {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-width: 315px;
}

.panelResizeHandle {
  transition: background-color 200ms ease-in-out;
  transition-delay: 300ms;
  outline: none;
  flex: 0 0 .35rem;
  justify-content: stretch;
  align-items: stretch;
  display: flex;
  &:hover {
    background-color: lightgrey;
    transition: background-color 0ms ease-in-out;
    transition-delay: 0s;
  }
}
